import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import partial from 'ramda/src/partial';
import values from 'ramda/src/values';
import i18n from 'invision-core/src/components/i18n/i18n';
import Configurable from 'invision-core/src/components/configurable/configurable';
import {
    BusinessUnitCustomPagesTemplateSelector,
    BusinessUnitCustomWidgetsTemplateSelector,
    BusinessUnitHasTemplateSelector,
    BusinessUnitTemplateAndGroupTemplateSelector,
    BusinessUnitFetchFeatureToggleValuesSelector,
    BusinessUnitFeatureToggleSelector
} from 'invision-core/src/components/configurable/configurable.selectors';
import {
    IsDbss,
    CurrentBusinessUnitSelector
} from 'invision-core/src/components/session/businessunit.selectors';
import {IsConvergentBillerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {
    hasAdminAccess,
    hasAccess
} from 'invision-core/src/components/security/permission.service';
import {RouteCurrentStateSelector} from 'invision-core/src/components/router/router.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {MetadataCodeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    CurrentCustomerSelector,
    HasConvergentBillerIdSelector,
    IsCurrentCustomerBlocklisted,
    IsCurrentCustomerDisconnectedSelector,
    IsCurrentCustomerReadyForOrdersSelector,
    IsExistingCartSelector,
    IsExistingStoreOrderSelector,
    IsSelectedCustomerWithPinSelector,
    IsTransferCartFromCurrentCustomerSelector,
    RouteParams
} from './customer.selectors';
import {
    ConvergentBillerAccountOfferingsForSelectedAccountSelector,
    HasPrepaidAndPostpaidOffersSelector
} from './customer.convergent.biller.selectors';
import {CustomerAccountStatusSelector} from './customer.account.status.selectors';
import {CUSTOMER_CATEGORY} from '../../components/createCustomerPopup/create.customer.popup.constants';
import {
    ACCOUNT_HIERARCHY_PAGE,
    CUSTOMER_PAGES_MAP,
    DEMO_ACTIONS_PAGE,
    HOUSEHOLD_PAGE,
    PRODUCTS_PAGE,
    SUBSCRIPTIONS_PAGE
} from '../../components/customerActionsTemplate/customer.pages';
import {QUICK_ACTIONS_MAP} from '../../components/customerActionsTemplate/quick.actions';
import {GLOBAL_ACTIONS_MAP} from '../../components/customerActionsTemplate/global.actions';
import CustomerCareLocaleKeys from '../../locales/keys';
import {CASE_ACCESS} from '../../security.attributes';
import {IsCustomerWrapUpRequiredSelector} from './search.selectors';
import {MetadataSelectors} from 'invision-core';

const {CUSTOMER_PAGE_IDS} = Configurable.CustomerCare;
export const CustomerActionsTemplateStoreSelector = (state) => {
    return state.customercare.customerActionsTemplate;
};

export const SelectedNavGroupSelector = createSelector(
    [CustomerActionsTemplateStoreSelector],
    (actionsTemplate) => {
        return actionsTemplate.selectedNavGroup;
    }
);

export const CustomerTemplateSelector = createSelector(
    [
        BusinessUnitHasTemplateSelector,
        BusinessUnitTemplateAndGroupTemplateSelector,
        IsDbss
    ],
    (hasTemplate, businessUnitTemplateAndGroupTemplate, isDbss) => {
        return hasTemplate
            ? businessUnitTemplateAndGroupTemplate
            : isDbss
                ? Configurable.dbssTemplate
                : Configurable.ottTemplate;
    }
);

const customerHasAccess = partial((securityAttributes, item) => {
    return (
        item &&
        (!item.securityAttributes ||
            item.securityAttributes.every((sa) => {
                return hasAccess(securityAttributes, sa);
            })) &&
        (!item.adminAttributes ||
            item.adminAttributes.every((attribute) => {
                return hasAdminAccess(securityAttributes, attribute);
            }))
    );
});

const translateMap = (map) => {
    let translated = Immutable({});
    values(map).forEach((item) => {
        translated = translated.set(
            item.id,
            item.set('label', i18n.translate(item.label))
        );
    });
    return translated;
};

const mergeSources = (orderOfItems = [], configurableMap, customerCareMap) => {
    return orderOfItems.map((id) => {
        return customerCareMap[id]
            ? customerCareMap[id].merge(configurableMap[id] || {}, {
                deep: true
            })
            : id;
    });
};

export const DashboardOfferingsWidgetUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvDashboardOfferingsWidgetEnabled;
    }
);

export const DashboardServicesWidgetUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvDashboardServicesWidgetEnabled;
    }
);

export const DashboardSharedUsageWidgetUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvDashboardSharedUsageWidgetEnabled;
    }
);

export const EwalletBetaUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvEwalletBetaEnabled;
    }
);

export const ProductBetaUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvProductBetaEnabled;
    }
);

export const ServicesAndUsageBetaUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvServicesAndUsageBetaEnabled;
    }
);

export const OfferingsPageUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvOfferingsPageEnabled;
    }
);

export const ServiceDetailsPageUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvServiceDetailsPageEnabled;
    }
);

export const ReactCreateCustomerModalToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvCreateCustomerBetaEnabled;
    }
);

export const MaskCreditCardNumberSelector = createSelector(
    [BusinessUnitFeatureToggleSelector],
    (featureToggle) => {
        return featureToggle.InvMaskCreditCardNumberEnabled;
    }
);

export const InvisionCareModuleNameCustomizationUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvCareModuleNameCustomizationEnabled;
    }
);

export const ServiceFeatureOrderingUIFeatureToggleSelector = createSelector(
    [BusinessUnitFetchFeatureToggleValuesSelector],
    (featureToggle) => {
        return featureToggle.InvServiceFeatureOrderingEnabled;
    }
);

export const NoCustomWidgetsConfiguredSelector = createSelector(
    [CustomerTemplateSelector],
    (template) => {
        return !(template.widgets && template.widgets.length > 0);
    }
);

const EMPTY_ARRAY = [];
export const CustomerTemplateWidgetSelector = createSelector(
    [
        CustomerTemplateSelector,
        BusinessUnitCustomWidgetsTemplateSelector,
        MetadataCodeSelector(CODES.BusinessUnitTemplate)
    ],
    (template, customWidgets, templateStore) => {
        if (!template.widgets) {
            return EMPTY_ARRAY;
        }
        // because of the requests that the account status widget makes upon load, we will not load it until we have all the template information loaded.
        const templateLoadComplete = Boolean(
            templateStore && (templateStore.isLoaded || templateStore.error)
        );
        const widgetList = [];

        template.widgets.map((widget) => {
            let isSystemWidget = false;
            const displayAccountStatus =
                Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                    .ACCOUNT_STATUS === widget;
            const widgetDisplay = {
                displayAccountBalanceTreatment:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .BALANCE_AND_TREATMENT === widget &&
                    (isSystemWidget = true),
                displayAccountStatus:
                    displayAccountStatus &&
                    templateLoadComplete &&
                    (isSystemWidget = true),
                displayActiveProducts:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .ACTIVE_PRODUCTS === widget && (isSystemWidget = true),
                displayDeviceFinancing:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .DEVICE_FINANCING === widget && (isSystemWidget = true),
                displayNextBestOffering:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .NEXT_BEST_OFFERING === widget &&
                    (isSystemWidget = true),
                displayOfferingDeprecated:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .OFFERING_DEPRECATED === widget &&
                    (isSystemWidget = true),
                displayOfferings:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .OFFERINGS === widget && (isSystemWidget = true),
                displayOpenInvoices:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .OPEN_INVOICES === widget && (isSystemWidget = true),
                displayProducts:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .PRODUCTS === widget && (isSystemWidget = true),
                displayServices:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .SERVICES === widget && (isSystemWidget = true),
                displayServicesDeprecated:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .SERVICES_DEPRECATED === widget &&
                    (isSystemWidget = true),
                displaySubscriptions:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .SUBSCRIPTIONS === widget && (isSystemWidget = true),
                displayRecommendationWidget:
                    Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS
                        .RECOMMENDATION === widget && (isSystemWidget = true)
            };
            if (!displayAccountStatus || templateLoadComplete) {
                widgetList.push(
                    isSystemWidget
                        ? widgetDisplay
                        : Object.assign(widgetDisplay, {
                            displayCustomWidget:
                                  customWidgets &&
                                  Object.values(customWidgets).find(
                                      (customWidget) => {
                                          return customWidget.Id === widget;
                                      }
                                  )
                        })
                );
            }
        });
        const bothServiceAndOfferWidgetEnabled = [];
        widgetList.forEach((widget, index) => {
            if (widget.displayServices) {
                bothServiceAndOfferWidgetEnabled.push({
                    displayServices: widget.displayServices,
                    index
                });
            }
            if (widget.displayOfferings) {
                bothServiceAndOfferWidgetEnabled.push({
                    displayOfferings: widget.displayOfferings,
                    index
                });
            }
        });

        if (
            bothServiceAndOfferWidgetEnabled.length > 1 &&
            bothServiceAndOfferWidgetEnabled.every((widget) => {
                return (
                    (widget && widget.displayServices) ||
                    widget.displayOfferings
                );
            })
        ) {
            const widgetsSorted = bothServiceAndOfferWidgetEnabled.sort(
                (widgetA, widgetB) => {
                    return widgetA.index - widgetB.index;
                }
            );

            widgetList.splice(widgetsSorted[0].index, 0, {
                displayServicesAndOfferingsWidget: true
            });
            widgetList.find((widget) => {
                if (widget.displayServices) {
                    Object.assign(widget, {
                        displayServices: false
                    });
                }
                if (widget.displayOfferings) {
                    Object.assign(widget, {
                        displayOfferings: false
                    });
                }
            });
        }
        return widgetList;
    }
);

const mergeCustomPages = (pageList, customPages = []) => {
    return pageList.map((page) => {
        if (typeof page === 'string') {
            const matchingCustomPage = Object.values(customPages).find(
                (customPage) => {
                    return customPage.Id === page;
                }
            );

            if (matchingCustomPage) {
                return Immutable({
                    id: matchingCustomPage.Id,
                    label: matchingCustomPage.Title,
                    state: 'index.customercare.customer.customPage',
                    params: {
                        pageId: matchingCustomPage.Id
                    }
                });
            } else {
                return page;
            }
        }

        return page;
    });
};

export const CustomerPageSelector = createSelector(
    [
        CustomerTemplateSelector,
        BusinessUnitCustomPagesTemplateSelector,
        RouteParams
    ],
    (template, customPages, routeParams) => {
        const translatedConfigurablePages = translateMap(
            Configurable.CustomerCare.CUSTOMER_PAGES_MAP
        );
        const combined = mergeSources(
            template.pages,
            translatedConfigurablePages,
            CUSTOMER_PAGES_MAP
        );
        const combinedWithCustomPages = mergeCustomPages(combined, customPages);
        return combinedWithCustomPages.map((page) => {
            return page
                .set('params', {
                    ...page.params,
                    ...routeParams
                })
                .set(
                    'subNavigation',
                    mergeSources(
                        template.subNavigation[page.id],
                        translatedConfigurablePages,
                        CUSTOMER_PAGES_MAP
                    )
                );
        });
    }
);

export const CustomFilteredCustomerPages = createSelector(
    [
        CustomerPageSelector,
        CurrentBusinessUnitSelector,
        CurrentCustomerSelector,
        IsDbss,
        UserSecurityAttributesSelector
    ],
    (pages, currentBU, currentCustomer, isDbss, userSecurityAttributes) => {
        return pages.filter((page) => {
            switch (page.id) {
                case CUSTOMER_PAGE_IDS.HOUSEHOLD:
                    return (
                        currentCustomer.Category !==
                            CUSTOMER_CATEGORY.COMMERCIAL &&
                        currentBU.householdEnabled
                    );
                case CUSTOMER_PAGE_IDS.SERVICES:
                    return isDbss;
                case CUSTOMER_PAGE_IDS.CASES:
                    return hasAccess(userSecurityAttributes, CASE_ACCESS);
                default:
                    return true;
            }
        });
    }
);

export const FilteredCustomerPages = createSelector(
    [
        CustomFilteredCustomerPages,
        UserSecurityAttributesSelector,
        HasConvergentBillerIdSelector,
        IsDbss
    ],
    (pages, securityAttributes = {}, hasConvergentBillerId, isDbss) => {
        const hasAccessForCurrentCustomer = customerHasAccess([
            securityAttributes
        ]);
        return pages.filter(hasAccessForCurrentCustomer).map((page) => {
            let modifiedPage = page;
            if (
                ACCOUNT_HIERARCHY_PAGE.id === modifiedPage.id ||
                DEMO_ACTIONS_PAGE.id === modifiedPage.id ||
                ((HOUSEHOLD_PAGE.id === modifiedPage.id ||
                    PRODUCTS_PAGE.id === modifiedPage.id ||
                    SUBSCRIPTIONS_PAGE.id === modifiedPage.id) &&
                    isDbss)
            ) {
                modifiedPage = modifiedPage.set(
                    'isLocked',
                    !hasConvergentBillerId
                );
                modifiedPage = modifiedPage.set(
                    'lockMessage',
                    i18n.translate(modifiedPage.lockMessage)
                );
            }
            if (modifiedPage.subNavigation) {
                modifiedPage = modifiedPage.set(
                    'subNavigation',
                    modifiedPage.subNavigation.filter(
                        hasAccessForCurrentCustomer
                    )
                );
            }

            return modifiedPage;
        });
    }
);

const isChildRoute = (subState = '', routeName = '') => {
    return subState.startsWith(`${routeName}.`);
};

export const FilteredCustomerPagesWithActiveRoute = createSelector(
    [
        FilteredCustomerPages,
        SelectedNavGroupSelector,
        RouteCurrentStateSelector
    ],
    (pages, selectedNavGroup, currentState) => {
        return pages.map((page) => {
            return page.set(
                'active',
                (currentState &&
                    (currentState.name === page.state ||
                        isChildRoute(currentState.name, page.state) ||
                        (page.subNavigation &&
                            page.subNavigation.some(({state}) => {
                                return state === currentState.name;
                            })))) ||
                    selectedNavGroup === page.id
            );
        });
    }
);

const CustomerQuickActionsSelector = () => {
    return translateMap(Configurable.CustomerCare.QUICK_ACTIONS_MAP);
};

const {CUSTOMER_QUICK_ACTION_IDS} = Configurable.CustomerCare;
const LOCKED_PROPERTY = 'isLocked';

const offerOptionsActiveOrRemovedOrSuspended = (offer) => {
    const hasOfferOptions = offer.Options && offer.Options.length > 0;

    //If there's no offer options just return true
    if (!hasOfferOptions) {
        return offer.Status === OFFER_STATUS_INDICATOR_STATUS.ACTIVE;
    } else {
        return offer.Options.every((option) => {
            return (
                OFFER_STATUS_INDICATOR_STATUS.ACTIVE === option.Status ||
                OFFER_STATUS_INDICATOR_STATUS.REMOVED === option.Status ||
                OFFER_STATUS_INDICATOR_STATUS.SUSPENDED === option.Status ||
                OFFER_STATUS_INDICATOR_STATUS.PENDING_SUSPENSION ===
                    option.Status
            );
        });
    }
};

export const FilteredCustomerQuickActionsSelector = createSelector(
    [
        CustomerTemplateSelector,
        CustomerQuickActionsSelector,
        UserSecurityAttributesSelector
    ],
    (template, quickActions, securityAttributes = {}) => {
        const hasAccessForCurrentCustomer = customerHasAccess([
            securityAttributes
        ]);
        return mergeSources(
            template.quickActions,
            quickActions,
            QUICK_ACTIONS_MAP
        ).filter(hasAccessForCurrentCustomer);
    }
);

export const HasOffersCombinedSelector = createSelector(
    [ConvergentBillerAccountOfferingsForSelectedAccountSelector],
    (currentDbssOfferings) => {
        return !!currentDbssOfferings;
    }
);

export const CustomerAccountStatusViewIsViewable = createSelector(
    [CustomerTemplateWidgetSelector],
    (widgetList) => {
        let hasAccountStatus = false;
        if (widgetList) {
            widgetList.forEach((widget) => {
                hasAccountStatus =
                    widget.displayAccountStatus || hasAccountStatus;
            });
        }
        return hasAccountStatus;
    }
);

export const CustomFilteredCustomerQuickActionsSelector = createSelector(
    [
        FilteredCustomerQuickActionsSelector,
        CustomerAccountStatusSelector,
        HasOffersCombinedSelector,
        IsExistingCartSelector,
        UserSecurityAttributesSelector,
        IsCustomerWrapUpRequiredSelector,
        IsExistingStoreOrderSelector,
        IsTransferCartFromCurrentCustomerSelector,
        IsCurrentCustomerDisconnectedSelector,
        CurrentCustomerSelector
    ],
    (
        actions,
        accountStatus,
        hasOffers,
        isExistingCart,
        userSecurityAttributes,
        isCustomerWrapUpRequired,
        isExistingStoreOrder,
        isTransferCart,
        isDisconnectedCustomer,
        currentCustomer
    ) => {
        return actions.filter((item) => {
            switch (item.id) {
                case CUSTOMER_QUICK_ACTION_IDS.DISCONNECT_CUSTOMER:
                    return !isDisconnectedCustomer && hasOffers;
                case CUSTOMER_QUICK_ACTION_IDS.RESET_DEVICE_PIN:
                    return (
                        accountStatus &&
                        accountStatus.DeviceSessionPromotionPinExists
                    );
                case CUSTOMER_QUICK_ACTION_IDS.RESUME_ORDER:
                case CUSTOMER_QUICK_ACTION_IDS.CLEAR_SAVED_CART:
                    return isExistingCart && !isTransferCart;
                case CUSTOMER_QUICK_ACTION_IDS.REVIEW_STORE_ORDER:
                case CUSTOMER_QUICK_ACTION_IDS.CANCEL_STORE_ORDER:
                    return isExistingStoreOrder;
                case CUSTOMER_QUICK_ACTION_IDS.UNREVOKE_LOGIN:
                    return accountStatus && accountStatus.LoginRevoked;
                case CUSTOMER_QUICK_ACTION_IDS.OPEN_CASE:
                    return hasAdminAccess(userSecurityAttributes, CASE_ACCESS);
                case CUSTOMER_QUICK_ACTION_IDS.CUSTOMER_WRAP_UP:
                    return isCustomerWrapUpRequired;
                case CUSTOMER_QUICK_ACTION_IDS.CHANGE_BILLING_TYPE:
                    return currentCustomer?.HierarchyParentSubscriberId;
                case CUSTOMER_QUICK_ACTION_IDS.MANAGE_SERVICES:
                    return currentCustomer?.Id && currentCustomer?.HierarchyId;
                case CUSTOMER_QUICK_ACTION_IDS.CREATE_COMMERCIAL_CHILD:
                    return currentCustomer?.Id && currentCustomer?.HierarchyId;
                default:
                    return true;
            }
        });
    }
);

export const AccountOfferingsSelector = createSelector(
    [IsDbss, ConvergentBillerAccountOfferingsForSelectedAccountSelector],
    (isDbss, dbssOfferings) => {
        return dbssOfferings || EMPTY_ARRAY;
    }
);

export const FilteredQuickActionsWithLocked = createSelector(
    [
        AccountOfferingsSelector,
        CustomFilteredCustomerQuickActionsSelector,
        HasConvergentBillerIdSelector,
        IsConvergentBillerSelector,
        IsCurrentCustomerBlocklisted,
        HasPrepaidAndPostpaidOffersSelector,
        IsSelectedCustomerWithPinSelector,
        IsCurrentCustomerReadyForOrdersSelector
    ],
    (
        currentOfferings,
        quickActions,
        hasConvergentBillerId,
        isConvergentBillerBU,
        isCurrentCustomerBlocklisted,
        hasPrepaidAndPostpaid,
        customerHasPIN,
        isCurrentCustomerReadyForOrders
    ) => {
        return quickActions.map((item) => {
            let completedItem;
            let lockMessage = item.lockMessage;

            switch (item.id) {
                case CUSTOMER_QUICK_ACTION_IDS.NEW_DBSS_ORDER:
                    completedItem = item.set(
                        LOCKED_PROPERTY,
                        !isCurrentCustomerReadyForOrders
                    );
                    if (
                        !completedItem[LOCKED_PROPERTY] &&
                        isCurrentCustomerBlocklisted
                    ) {
                        completedItem = item.set(
                            LOCKED_PROPERTY,
                            isCurrentCustomerBlocklisted
                        );
                        lockMessage =
                            CustomerCareLocaleKeys.CUSTOMER_DASHBOARD
                                .ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
                    }
                    break;
                case CUSTOMER_QUICK_ACTION_IDS.REDEEM_GIFT_ORDER:
                    completedItem = item.set(
                        LOCKED_PROPERTY,
                        isCurrentCustomerBlocklisted
                    );
                    if (isCurrentCustomerBlocklisted) {
                        lockMessage =
                            CustomerCareLocaleKeys.CUSTOMER_DASHBOARD
                                .ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
                    }
                    break;
                case CUSTOMER_QUICK_ACTION_IDS.DISCONNECT_CUSTOMER: {
                    const hasMultipleContracts = currentOfferings
                        ? currentOfferings.filter((offering) => {
                            return offering.OrderContractInstance;
                        }).length > 1
                        : false;
                    const isEveryOfferOptionActiveOrRemovedOrSuspended =
                        currentOfferings &&
                        currentOfferings.every(
                            offerOptionsActiveOrRemovedOrSuspended
                        );
                    if (
                        hasMultipleContracts &&
                        !isEveryOfferOptionActiveOrRemovedOrSuspended
                    ) {
                        lockMessage =
                            CustomerCareLocaleKeys.MULTIPLE_CONTRACTS_CAN_NOT_FULL_DISCONNECT;
                    } else if (hasPrepaidAndPostpaid) {
                        lockMessage =
                            CustomerCareLocaleKeys.PRE_AND_POSTPAID_OFFERS_CAN_NOT_FULL_DISCONNECT;
                    }
                    completedItem = item.set(
                        LOCKED_PROPERTY,
                        !isEveryOfferOptionActiveOrRemovedOrSuspended ||
                            hasMultipleContracts ||
                            hasPrepaidAndPostpaid
                    );
                    break;
                }
                case CUSTOMER_QUICK_ACTION_IDS.NEW_PRODUCT_ORDER:
                    completedItem = item.set(
                        LOCKED_PROPERTY,
                        !isCurrentCustomerReadyForOrders
                    );
                    if (
                        !completedItem[LOCKED_PROPERTY] &&
                        isCurrentCustomerBlocklisted
                    ) {
                        completedItem = item.set(
                            LOCKED_PROPERTY,
                            isCurrentCustomerBlocklisted
                        );
                        lockMessage =
                            CustomerCareLocaleKeys.CUSTOMER_DASHBOARD
                                .ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
                    }
                    break;
                case CUSTOMER_QUICK_ACTION_IDS.START_NEW_SUBSCRIPTION:
                    completedItem = item.set(
                        LOCKED_PROPERTY,
                        isConvergentBillerBU ? !hasConvergentBillerId : false
                    );
                    if (
                        !completedItem[LOCKED_PROPERTY] &&
                        isCurrentCustomerBlocklisted
                    ) {
                        completedItem = item.set(
                            LOCKED_PROPERTY,
                            isCurrentCustomerBlocklisted
                        );
                        lockMessage =
                            CustomerCareLocaleKeys.CUSTOMER_DASHBOARD
                                .ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
                    }
                    break;
                case CUSTOMER_QUICK_ACTION_IDS.VALIDATE_PIN:
                    completedItem = item.set(LOCKED_PROPERTY, !customerHasPIN);
                    if (!customerHasPIN) {
                        lockMessage =
                            CustomerCareLocaleKeys.CUSTOMER_DASHBOARD
                                .VALIDATE_PIN_LOCK_MESSAGE;
                    }
                    break;
                default:
                    completedItem = item;
                    break;
            }
            return item.id
                ? completedItem.set('lockMessage', i18n.translate(lockMessage))
                : item;
        });
    }
);

const CustomerGlobalActionsSelector = () => {
    return translateMap(Configurable.CustomerCare.GLOBAL_ACTIONS_MAP);
};

const FilteredCustomerGlobalActionsSelector = createSelector(
    [
        CustomerTemplateSelector,
        CustomerGlobalActionsSelector,
        UserSecurityAttributesSelector
    ],
    (template, globalActions, securityAttributes = {}) => {
        const hasAccessForCurrentCustomer = customerHasAccess([
            securityAttributes
        ]);
        return mergeSources(
            template.globalActions,
            globalActions,
            GLOBAL_ACTIONS_MAP
        ).filter(hasAccessForCurrentCustomer);
    }
);

export const CustomerActionsTemplateSelector = createSelector(
    [
        FilteredCustomerGlobalActionsSelector,
        FilteredCustomerPagesWithActiveRoute,
        FilteredQuickActionsWithLocked
    ],
    (globalActions, pages, quickActions) => {
        return {
            globalActions,
            pages,
            quickActions
        };
    }
);

export const NonTelcoUsageServiceFeatureToggleSelector = createSelector(
    [MetadataSelectors.codes.MetadataCodeTypeSelector(CODES.FeatureToggleConfig)],
    (feaureToggleCodes) => {
        if (feaureToggleCodes?.length) {
            const nonTelcoFeatureCodes= feaureToggleCodes.filter(featureToggleCode => {
                return findAdditonalProperty(featureToggleCode?.AdditionalProperties, 'EnableFinancialBankingUsageDetails', 'Value');
            });
            const isNonTelcoFeatureEnabled = findAdditonalProperty(nonTelcoFeatureCodes?.find((nonTelcoFeatureCode) => {
                return !nonTelcoFeatureCode.hasOwnProperty('Global');
            }
            )?.AdditionalProperties, 'enabled')?.Value === 'True' ? true : false;
            const isNonTelcoFeatureGlobalValue = findAdditonalProperty(nonTelcoFeatureCodes?.find((nonTelcoFeatureCode) => {
                return nonTelcoFeatureCode.hasOwnProperty('Global');
            }
            )?.AdditionalProperties, 'enabled')?.Value === 'True' ? true : false;
            return isNonTelcoFeatureEnabled || isNonTelcoFeatureGlobalValue;
        }
        return false;
    }
);

export function findAdditonalProperty(additionalProperties, targetProperty, type='Key') {
    const findProperty = (property) => {
        return property[type] === targetProperty;
    };
    return additionalProperties?.find(findProperty);
}
